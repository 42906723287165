<template>
  <div class="Maintenance">
    <img src="../assets/xtwh.png" alt=""/>
    <p>System under maintenance！</p>
  </div>

</template>

<script setup>

</script>

<style scoped lang="less">
.Maintenance{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  img{
    width: 220px;
    height: 175px;
    margin-top: -100px;
  }
  p{
    font-size: 15px;
    font-weight: 500;
    color: #6E7BE4;
    margin: 5px;
  }
}
</style>
