<template>
  <div class="back flex_between">
    <van-icon name="arrow-left" @click="goBack"/>
    <span>Details</span>
  </div>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="loading" loosing-text="loading" loading-text="loading" success-text="success" >
    <div class="Details">
      <div class="flex_between">
        <div class="time" v-if="orderData.createTime">{{toHHmmss(orderData.createTime,+0)}}</div>
        <div class="type" :style="(orderData.orderStatus==2 ||
                       orderData.orderStatus==8 ||
                        orderData.orderStatus==9 ||
                         orderData.orderStatus==13 ||
                          orderData.orderStatus==15 ||
                          orderData.orderStatus==22 ||
                            orderData.orderStatus==19) ?'color: #339D8E;':'color: #6E7BE4;'"
        >{{getOrderStatus(orderData.orderStatus)}}</div>
      </div>
      <div class="card" >
        From
        <div class="flex_between img">
          <span>{{orderData.receiveType}}</span>
          <img :src="orderData.receiveType==='Statter'?require('../assets/stt.png'):require('../assets/bscimg.png')" alt=""/>
        </div>
      </div>

      <div class="card" v-if="orderData.receiveAssets">
        Send Token
        <div class="flex_between img">
          <span>{{orderData.receiveAssets}}</span>
          <img :src="orderData.receiveAssetsIcon" alt="" v-if="orderData.receiveAssetsIcon!=null"/>
        </div>
      </div>

      <div class="card" v-if="orderData.receiveAmount">
        Send Amount
        <div class="green">
         {{orderData.receiveAmount}}
        </div>
      </div>

      <div class="card"  v-if="orderData.receiveAddressStr">
        Address
        <div class="copy">
          <span  class="copyInfo"> {{orderData.receiveAddress}}</span>
          <img src="../assets/copy.png" @click="copyUrl(orderData.receiveAddressStr)"/>
        </div>
      </div>

      <div class="card"  v-if="orderData.receiveHash">
        Transaction Hash
        <div class="copy">
          <span  class="copyInfo"> {{orderData.receiveHash}}</span>
          <img src="../assets/copy.png" @click="copyUrl(orderData.receiveHash)"/>
        </div>
      </div>

      <div class="card"  v-if="orderData.transferType">
        To
        <div class="flex_between img">
          <span>{{orderData.transferType}}</span>
          <img :src="orderData.transferType==='Statter'?require('../assets/stt.png'):require('../assets/bscimg.png')" alt=""/>
        </div>
      </div>

      <div class="card"  v-if="orderData.transferAssets">
        Receive Token
        <div class="flex_between img">
          <span>{{orderData.transferAssets}}</span>
          <img :src="transferAssetsIcon" alt="" v-if="transferAssetsIcon!=null"/>
        </div>
      </div>

      <div class="card"  v-if="orderData.transferAmount">
        Receive Amount
        <div class="blue">
          {{orderData.transferAmount}}
        </div>
      </div>

      <div class="card"  v-if="orderData.transferAddressStr">
        Address
        <div class="copy">
          <span  class="copyInfo"> {{orderData.transferAddress}}</span>
          <img src="../assets/copy.png" @click="copyUrl(orderData.transferAddressStr)"/>
        </div>
      </div>

      <div class="card"  v-if="orderData.transferHash">
        Transaction Hash
        <div class="copy">
          <span  class="copyInfo">{{orderData.transferHash}}</span>
          <img src="../assets/copy.png" @click="copyUrl(orderData.transferHash)"/>
        </div>
      </div>
      <div class="card"  v-if="orderData.swapHash">
        Swap Hash
        <div class="copy">
          <span  class="copyInfo">{{orderData.swapHash}}</span>
          <img src="../assets/copy.png" @click="copyUrl(orderData.swapHash)"/>
        </div>
      </div>
    </div>

  </van-pull-refresh>

</template>

<script setup>
import { Toast } from 'vant';
import {toHHmmss,getOrderStatus} from "@/utils/decimalTool";
import {getLogo,getOrderInfo} from "@/api";
import {ref, onMounted, watch, toRefs, nextTick} from "vue";
import useClipboard from 'vue-clipboard3'
const props = defineProps({
  id: {
    type: Boolean,
    default:false
  },

})
const emit = defineEmits()

const goBack=()=>{
  emit('showHome', false)
}

const isLoading=ref(false)
const orderData=ref({})
watch(
    props,
    (newProps) => {
      console.log(newProps) // 接收到的props的值
      nextTick(()=>{
        httpInit();
      })
    },
    { immediate: true, deep: true }
);




const { toClipboard } = useClipboard()

const copyUrl=async(info)=>{
  try {
    await toClipboard(info)
    Toast('Success')
  } catch (e) {
    Toast('Error')
  }
}


    const getIcon=(chainType,currencyName)=>{
      let imgUrl
      getLogo({
        "pageNo": 0, //请求 分页参数  当前页码
        "pageSize": 0, //请求 分页参数  每页条数
        "data": { //请求 查询参数
          "assets": currencyName, //币种
          "chainType": chainType //链类型
        }
      }).then(res=>{
        imgUrl= res.data
      })
      return imgUrl
    }
    const httpInit=()=>{
      getOrderInfo({
        "pageNo": 0, //请求 分页参数  当前页码
        "pageSize": 0, //请求 分页参数  每页条数
        "data": props.id //请求 查询参数
      }).then(res=>{
        orderData.value=res.data
        console.log(orderData.value.orderStatus)
          getIcon(orderData.value.receiveType,orderData.value.receiveAssets).then(res=>{
            orderData.value.receiveAssetsIcon=res
          })
        getIcon(orderData.value.transferType,orderData.value.transferAssets).then(res=>{
          orderData.value.transferAssetsIcon=res
        })
      })
    }
    const onRefresh=()=>{
      setTimeout(() => {
        Toast('success');
        httpInit()
       isLoading.value= false;
      }, 1000);
    }
</script>

<style scoped lang="less">
.van-pull-refresh {
  height:100vh !important;
   overflow-y: scroll !important;
}

.flex_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time{
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 23px;

}
.type{
  font-size: 16px;
  font-weight: bold;

  color: #6E7BE4;
  margin-bottom: 23px;
}
.Details{
  margin:75px 17px 24px 17px ;
  height: auto;
  .card{
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 17px;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 18px;
    .img{
      span{
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
      img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 7px;
      }
    }
    .green{
      font-size: 16px;
      font-weight: 500;
      color: #339D8E;
    }
    .copy{
      flex: 0.7;
      font-size: 13px;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img{
        width: 13px;
        height: 13px;
        margin-left: 7px;
      }
      span{
        word-break: break-all;
      }
    }
    .blue{
      font-size: 16px;
      font-weight: 500;
      color: #6E7BE4;

    }
  }
}
.back{
 padding:17px 17px  ;
  font-size: 22px;
  font-weight: bold;
  background: #FFFFFF;
  width: 100%;
  position: fixed;
  top:0;
  z-index: 1;
  span{
    text-align: center;
    flex: 1;
    font-size: 17px;
    margin-left: -4%;
  }
}
</style>
