// 导入axios实例
import http from '@/utils/request.js'

// 注册登录
export function login(data) {
    return http({
        url: '/login/login',
        method: 'post',
        data:data
    })
}
// 获取余额
export function getBalance(data) {
    return http({
        url: '/index/getBalance',
        method: 'post',
        data:data
    })
}
// 获取币种下拉框
export function getChainSelect(data) {
    return http({
        url: '/index/getChainSelect',
        method: 'post',
        data
    })
}
// 获取订单列表
export function getOrderRecord(data) {
    return http({
        url: '/order/getOrderRecord',
        method: 'post',
        data:data
    })
}
// 获取logo
export function getLogo(data) {
    return http({
        url: '/index/getLogo',
        method: 'post',
        data:data
    })
}

// 获取兑换数据信息
export function getSwapInfo(data) {
    return http({
        url: '/index/getSwapInfo',
        method: 'post',
        data:data
    })
}

// 获取订单详情
export function getOrderInfo(data) {
    return http({
        url: '/order/getOrderInfo',
        method: 'post',
        data:data
    })
}
// 下单
export function addOrder(data) {
    return http({
        url: '/order/addOrder',
        method: 'post',
        data:data
    })
}
// 绑定hash
export function bindHash(data) {
    return http({
        url: '/order/bindHash',
        method: 'post',
        data:data
    })
}

// 绑定hash
export function getRechargeSdkInfo(data) {
    return http({
        url: `/index/getRechargeSdkInfo?assetsName=${data}`,
        method: 'get',
    })
}
//获取当前币种
export function getCurrentAssets() {
    return http({
        url: '/index/getCurrentAssets',
        method: 'get',
    })
}
// 防重复判断
export function orderExist(data) {
    return http({
        url: '/order/orderExist',
        method: 'post',
        data:data
    })
}
// 首页判断
export function maintenanceInspection() {
    return http({
        url: '/index/maintenanceInspection',
        method: 'get',
    })
}
