import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import vant from 'vant';
import 'vant/lib/index.css';
import './utils/rem'
//调试器
import VConsole from 'vconsole'

if (process.env.NODE_ENV !== 'production') {
//     console.log('线上')
  const vConsole = new VConsole()
}

// 跳转后返回顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

createApp(App)
    .use(router)
    .use(vant)
    .mount('#app')
